body {
	font-family: $lato;
	&.modalOpen{
		overflow: hidden;
	}
	p{
		a{
			color: $dark-grey;
			text-decoration: underline;
			-webkit-text-decoration-color: $honda-red; /* Safari */
  			text-decoration-color: $honda-red
		}
	}

	.fc-white {
		p{
			a{
				color: #f51638;
				text-decoration: underline;
				-webkit-text-decoration-color: $honda-red; /* Safari */
				  text-decoration-color: $honda-red
			}
		}
	}
}

a{
	color: $dark-grey;
	text-decoration: none;
}

h1,h2,h3,h4,h5,h6 {
	font-family: $montserrat;
	font-weight: 800;
}

h1 {
	font-size: 50px;
}
h2 {
	font-size: 40px;
	margin: 1.424rem 0;
}
h3 {
	font-size: 35px;
}
h4 {
	font-size: 25px;
}
h5 {
	font-size: 18px;
}
h6 {
	font-size: 15px;
}

.layout{
	overflow-x: hidden;
}

.campaign-layout{
	margin:0;
	padding:0;
}

.customContainer {
	width: 85%;
	margin: 0 auto;
	
}

.mt64px {
	margin-top: 64px !important;
}
.mt32px {
	margin-top: 32px !important;
}
.mt16px {
	margin-top: 16px !important;
}
.mt8px {
	margin-top: 8px !important;
}
.mt0px {
	margin-top: 0px !important;
}

.mb64px {
	margin-bottom: 64px !important;
}
.mb32px {
	margin-bottom: 32px !important;
}
.mb16px {
	margin-bottom: 16px !important;
}
.mb8px {
	margin-bottom: 8px !important;
}
.mb0px {
	margin-bottom: 0px !important;
}


.hButton {
	background-color: transparent;
	border: 1px solid #ffffff;
	margin-right: 15px;
	color: #fff;
	font-size: 16px;
	font-weight: 700;
	box-shadow: none;
	transition: 0.2s ease-in-out;
	padding: 12px 24px;
	display: inline-block;
	height: auto;
	line-height: normal;
	text-transform: uppercase;
	border-radius: 0;
	&.buttonRed {
		background-color: $honda-red;
		border: 1px solid $honda-red;
	}
	&.outlineRed {
		background-color: white;
		border: 2px solid $honda-red;
		color: $honda-red;
		&:hover,
		&:focus {
			background-color: white;
		}
	}
	&:disabled,
	&[disabled] {
		background-color: #bdbdbd;
		border: 1px solid #bdbdbd;
		cursor: default;
	}
	&:last-child {
		margin-right: 0;
	}
	&.fw {
		width: 100%;
	}
}

.hiddenH1 {
	visibility: hidden;
	height: 0;
	overflow: hidden;
	margin: 0;
}

.introDescription {
	font-size: 20px;
	font-family: $montserrat;
	font-weight: 500;
	line-height: 22px;
}

.listSpecialOffers {
	padding: 20px 0;
	max-width: 1600px;
	margin: 0 auto;

	@media(max-width: 1700px){
		max-width: 1400px;
	}

	@media(max-width: 1440px){
		max-width: 100%;
	}

	.row {
		.col {
			.specialCard {
				margin-bottom: 20px;
			}
		}
	}
}

.lazyLoad {
	position: relative;
	display: block;
	overflow: hidden;
	.lazyLoadImg {
		display: block;
		width: 100%;
		height: auto;
		&.loading {
			opacity: 0;
		}
		&.loaded {
			opacity: 1;
		}
	}
	.placeholder {
		position: absolute;
		top: 0;
		left: 0;
		filter: blur(20px);
		overflow: hidden;
		transition: opacity 0.3s ease-in-out;
		width: 100%;
		height: 100%;
		&.small {
			filter: blur(10px);
		}
		&.product-blur {
			filter: blur(5px);
		}
		&.loading {
			opacity: 1;
		}
		&.loaded {
			opacity: 0;
		}
		img{
			width: 100%;
			height: auto;
		}
	}

	&.center {
		.lazyLoadImg {
			margin: 0 auto;
		}
		.placeholder {
			left: 50%;
			transform: translateX(-50%);
		}
	}
}

.waves-effect.waves-red .waves-ripple {
	/* The alpha value allows the text and background color
	of the button to still show through. */
	background-color: rgba($color: $honda-red, $alpha: 0.65);
}

.searchResults{
	margin: 55px auto;
	.resultsTitle{
		h2{
			margin-top: 0;
		}
	}
	.searchInput{
		.generalInput{
			margin-bottom: 0;
			h5, .hint{
				display: none;

			}
		}
	}
	.hButton{
		border: 4px solid #f51638;
	}
	.result{
		a{
			display: inline-block;
			border-bottom: 2px solid $white-grey;
			color: $dark-grey;
			transition: 0.3s ease-in-out;
			padding-bottom: 12px;
			h3{
				margin-top: 12px;
			}
		}
		&:hover{
			a{
				color: $honda-red;
			}
		}
	}
}

.main{
	padding: 0;
	margin: 0;
}

.formBuilderSubmit{
	span{
		margin-right: 10px;
		svg{
			font-size: 20px;
		}
	}
}

.errorBody {
	width: 100%;
	margin: 0 !important;
	height: 100% !important;
	overflow: hidden !important;
	background: $honda-red;
	z-index: 2;
	position: relative;

	.main404 {
		background-size: cover;
		height: auto;
		margin: 0 auto;
		width: 100%;
		object-fit: cover;
		z-index: 3;
	}

	&.main500 {
		background-size: cover;
		background: #e61f34;
		height: 100vh !important;
		margin: 0 auto;
		width: 100%;
		object-fit: cover;
		z-index: 3;
	}

	.main404Page {
		text-align: center;
		position: absolute;
		width: 100%;
		bottom: 10%;
		z-index: 6;
		p {
			padding-bottom:  1rem;
			font-weight: lighter;
			letter-spacing: 2px;
			line-height: 100%;
		}
		.whiteRedBtn {
			border: 2px solid #FFFFFF;
			font-weight: bold;
			color: #ffffff;
			background: transparent;
			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;
			letter-spacing: .5px;
			text-transform: uppercase;
			height: 54px;
			width: 200px;
			border-radius: 2px;
			&:hover {
				background: transparent;
			}
		}
	}
}

.pt-85{
	padding-top: 85px;
}
.pt-100{
	padding-top: 100px;
}

@media (max-width: 480px) {
	h4 {
		font-size: 18px;
	}
}

.iframe-player {
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 56.25%; /* 16:9 */
	margin: 32px 0 128px 0;
}

/* makes video content smaller */
.small-iframe-player {
	width: 1000px;
	max-width: 100%;

	.iframe-player {
		margin-bottom:60px;
	}
}

.iframe-player {
 	iframe{
		position: absolute;
		top: 0;
		left: 0;
		width: 100% !important;
		height: 100% !important;
	}
}

.cookies {
	position: fixed;
	background-color: white;
	width: 100%;
	bottom: 0;
	z-index: 1001;
	box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
	.wrapper {
		display: flex;
		align-items: center;
	}
	.img {
		display: flex;
		height: 100%;
	}
	.title-desc {
		height: 100%;
		padding: 20px 0;
		.img-title {
			display: flex;
			align-items: center;
			padding-bottom: 5px;
			h4.wht-space {
				padding-left: 10px;
			}
		}
	}
	button {
		margin-top: 10px;
		&.alt {
			margin-top: 20px;
		}
	}

}