.banner {
	position: relative;
	.bannerLink {
		display: block;
		position: relative;
		margin: 0;
		padding: 0;
		cursor: pointer;
	}
	.imageHolder {
		position: relative;
		display: -ms-grid;
		display: grid;
		.overlay {
			background: linear-gradient(
				to right,
				rgba(0, 0, 0, 0.7) 0%,
				rgba(0, 0, 0, 0.54) 43%,
				rgba(0, 0, 0, 0.23) 74%,
				rgba(0, 0, 0, 0) 100%
			);
			position: absolute;
			width: 50%;
			height: 100%;
			background-size: cover;
			z-index: 1;
			top: 0;
			.disc {
				letter-spacing: 1px;
				font-size: 1em !important;
				position: absolute;
				color: #d8d8d8;
				bottom: 5%;
				left: 15.5%;
				font-family: $lato;
			}
		}
		.countdownTimerHolder {
			z-index: 1000;
			position: absolute;
			width: 85%;
			top: 45%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			&.alignBottom {
				top: 75%;
			}
			&.alignCenter {
				top: 45%;
				width: initial;
			}
			.countdownTimer {
				position: absolute;
				top: -60px;
				// left: 50%;
				transform: translateX(-50%);
				// width: 50px;
				// height: 50px;
				// border-radius: 50%;
				// border: 2px solid white;
				color: $honda-red;
				display: flex;
				// justify-content: center;
				// align-items: center;
				// cursor: pointer;
				// z-index: 100;
				.expired {
					font-size: 4rem;
					line-height: 1;
					font-weight: 900;
					font-family: "Montserrat";
				}
				.unitWrapper {
					padding: 20px;
					margin: 5px auto;
					// background-color: rgba(5, 5, 5, 0.8);
					.unit {
						margin: 0 auto;
						// width: fit-content;
						width: 110px;
						font-size: 4rem;
						line-height: 1;
						display: flex;
						font-weight: 900;
						font-family: "Montserrat";
						justify-content: space-around;
					}
					.line {
						width: 100%;
						height: 3px;
						margin: 10px 0;
						background-color: $honda-red;
					}
					.description {
						color: white;
						display: flex;
						font-size: 2rem;
						justify-content: space-around;
					}
				}
			}
		}
	}
	.productBannerCopyHolder {
		z-index: 2;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		.productBannerCopy {
			position: absolute;
			width: 85%;
			top: 45%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			&.alignBottom {
				top: 75%;
			}
			&.alignCenter {
				top: 45%;
				width: initial;
			}
			p a {
				color: white;
				text-decoration-color: white;
				-webkit-text-decoration-color: white;
				-moz-text-decoration-color: white;
			}
			.copy {
				width: 50%;
				color: #ffffff;
				.line {
					width: 64px;
					height: 5px;
					background-color: $honda-red;
				}
				h1,
				h2 {
					font-size: 64px;
					line-height: 84px;
					margin: 0;
					margin-top: 16px;
					margin-bottom: 16px;
					display: block;
					font-weight: 900;
				}
				h4 {
					margin: 0;
					margin-bottom: 32px;
				}
				h6 {
					font-weight: 400;
					position: relative;
					line-height: 26px;
					display: block;
					margin: 0;
					margin-bottom: 32px;
					font-family: $lato;
					font-size: 16px;
				}
			}
			.buttons {
				margin-top: 43px;
				a {
					background-color: transparent;
					// border: 2px solid #ffffff;
					margin-right: 32px;
					// color: #fff;
					font-size: 16px;
					font-weight: 700;
					box-shadow: none;
					transition: 0.2s ease-in-out;
					padding: 12px 24px;
					display: inline-block;
					height: auto;
					line-height: normal;
					text-transform: uppercase;
					border-radius: 0;
					&.bbred {
						background-color: $honda-red;
						border: 2px solid $honda-red;
						color: white !important;
					}
					&.bbwhite {
						border: white 1px solid !important;
						color: white !important;
					}
					&.bblightGrey {
						border: white 1px solid !important;
						background-color: rgba(17, 17, 17, 0.4) !important;
						color: white !important;
					}
					&.bbdarkGrey {
						border: white 1px solid !important;
						background-color: rgb(17, 17, 17) !important;
						color: white !important;
					}
					&.bbsolidWhite {
						border: white 1px solid !important;
						background-color: rgb(255, 255, 255) !important;
						color: rgb(0, 0, 0) !important;
					}
				}
			}
		}
	}

	.arrow {
		position: absolute;
		bottom: 15px;
		left: 50%;
		transform: translateX(-50%);
		width: 50px;
		height: 50px;
		border-radius: 50%;
		color: white;
		border: 2px solid white;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		z-index: 100;
	}
}

.smallBanner {
	position: relative;
	.bannerLink {
		display: block;
		position: relative;
	}
	.imageHolder {
		position: relative;
		display: -ms-grid;
		display: grid;
		// min-height: 300px;//this may change when we use mobile images
		.overlay {
			background: linear-gradient(
				to right,
				rgba(0, 0, 0, 0.7) 0%,
				rgba(0, 0, 0, 0.54) 43%,
				rgba(0, 0, 0, 0.23) 74%,
				rgba(0, 0, 0, 0) 100%
			);
			position: absolute;
			width: 50%;
			height: 100%;
			background-size: cover;
			z-index: 1;
			top: 0;
			.disc {
				letter-spacing: 1px;
				font-size: 1em !important;
				position: absolute;
				color: #d8d8d8;
				bottom: 5%;
				left: 15.5%;
				font-family: $lato;
			}
		}
	}
	.productBannerCopyHolder {
		z-index: 2;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		.productBannerCopy {
			position: absolute;
			width: 85%;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			.copy {
				width: 50%;
				color: #ffffff;
				.line {
					width: 64px;
					height: 5px;
					background-color: $honda-red;
				}
				h1,
				h2 {
					font-size: 64px;
					line-height: 84px;
					margin: 0;
					margin-top: 16px;
					margin-bottom: 16px;
					display: block;
					font-weight: 900;
				}
				h4 {
					margin: 0;
					margin-bottom: 32px;
				}
				h6 {
					font-weight: 400;
					position: relative;
					line-height: 26px;
					display: block;
					margin: 0;
					margin-bottom: 32px;
					font-family: $lato;
					font-size: 16px;
				}
			}
		}
	}

	.arrow {
		position: absolute;
		bottom: 15px;
		left: 50%;
		transform: translateX(-50%);
		width: 50px;
		height: 50px;
		border-radius: 50%;
		color: white;
		border: 2px solid white;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		z-index: 100;
	}
}
